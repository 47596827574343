import Page from "../../../../templates/Page";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { getMenuItemPrice } from "../../../order/menu_utils";
import SelectionItem from "./SelectionItem";
import { useNoSessionContext } from "../../../../../system/NoSessionProvider";
import { useSessionContext } from "../../../../../system/SessionProvider";
import { useCallback, useMemo, useRef } from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { getEnv } from "../../../../../system/env";
import { getOverrideImage, orderMenuOverride } from "./menu_overrides";
import { PageTitle, PageSubtitle } from "../../../../atoms/TextStyles";
import "./SelectionPage.css"
import { useCartContext } from "../../../../../system/CartProvider";
import CheckoutBar from "./CheckoutBar";
import { DrinkNameEntryModal, DrinkNameEntryModalHandle } from "./DrinkNameEntry";
import Footer from "../../../../molecules/footer";
import { OrderStackParamList } from "../../../order/order_param_list";
import { MenuItem } from "../../../../../vale_common";
import { previewSrc } from "./reviewOrderUtil";
import { useValeContext } from "../../../../../system/ValeSystem";
import { Box } from "@mui/material";
import { getStoreStatus } from "../../../../../system/menu/menuUtils";

const env = getEnv()
const SelectionPage: React.FC<
  NativeStackScreenProps<OrderStackParamList, "selection">
> = (props) => {
  const vale = useValeContext();
  const cart = useCartContext();
  const noSessionInfo = useNoSessionContext();
  const session = useSessionContext();
  const drinkNameModalRef = useRef<DrinkNameEntryModalHandle>(null);

  const navigation = useNavigation<StackNavigationProp<OrderStackParamList>>();

  const onCheckout = useCallback(() => {
    navigation.push("cart", {});
  },[])

  const storeIsClosed = useMemo(() => {
    const storeStatusInfo = getStoreStatus(noSessionInfo?.locations);
    
    return storeStatusInfo?.storeStatus != "OPEN"
  }, [noSessionInfo?.locations]);

  const onStoreClosed = useMemo(() => {
    //@ts-ignore
    return storeIsClosed ? () => navigation.navigate("hours") : undefined
  }, [storeIsClosed]);
  
  const onPress = useCallback(async (menuItem: MenuItem)=>{
    if ( !(cart!.orderName) && !session?.userData?.userRecord?.displayName ) {
      //If no name entry, go get it.
      //We auto populate with the users name if they are logged in.
      const name = await drinkNameModalRef.current?.getName(undefined);
      if (!name) {
        return false
      }

      cart?.setOrderName?.(name);
    }

    cart?.addItem({itemTypeId: menuItem.id!, itemTypeVariationId: menuItem.variations![0].id!, price: menuItem.variations?.[0].price || {}, name: menuItem.name!});

    return true;
  }, [drinkNameModalRef, cart!.orderName, cart?.setOrderName, cart?.addItem, vale?.valeClientOpenapi])

  if (!noSessionInfo?.menuItems) return null;

  const orderedList = orderMenuOverride(Array.from(noSessionInfo?.menuItems?.values()!))

  const menuItems = orderedList.map(
    (menuItem, index) => {  
      console.log("menuItem : ", menuItem)
      const imageUrl = getOverrideImage(menuItem.name) ?? menuItem.imageUrl;
      const notAvailable = ((menuItem?.variations?.map(v => v.absentAtLocationIds?.includes(env.squareDefaultLocationId) || false).filter( v => !!v)) || []).length > 0
      const ordered = cart?.items.find((item) => item.itemTypeId === menuItem.id);

      return (
          <SelectionItem
            onStoreClosed={onStoreClosed}
            key={menuItem.id}
            iconUrl={imageUrl}
            onPress={()  => onPress(menuItem)}
            name={menuItem.name!}
            description={menuItem.description!}
            price={getMenuItemPrice(menuItem)}
            notAvailable={notAvailable}
            ordered={!!ordered}  
          />
      );
    },
  );

  return (
    <Page>
      <div >
        <PageTitle>{storeIsClosed ? "PEEP THE MENU" : "CHOOSE YOUR DRINK"}</PageTitle>
        <PageSubtitle>{storeIsClosed ? "We’re not open right now but feel free to take a look around. " : "All drinks are 12oz of 100% pure flavor."}</PageSubtitle>
      </div>

      <div className="selection-page-flex-container">
        <div className="selection-page-grid-container">
          {menuItems}
        </div>
      </div>

      <Footer />
      <CheckoutBar onCheckout={onCheckout}/>
      <DrinkNameEntryModal ref={drinkNameModalRef}/>
    </Page>
  );
};

export default SelectionPage;
