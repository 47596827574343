import React from "react";
import { Box, Typography } from "@mui/material";
import { StyleSheet } from "react-native";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
import { PageSubtitle, PageTitle } from "../../atoms/TextStyles";
import RewardComponent from "./RewardComponent";

const OmgRewardsBox = () => {

    const freeDrink = {
        name: 'Free Drink',
        imageUrl: './images/loyalty/loyalty_freedrink.png'
    };

    const StickerPack = {
        name: 'Sticker Pack',
        imageUrl: './images/loyalty/loyalty_stickers.png'
    };
  
  return (
    <div style={styles.mainBox}>


      <div style={styles.headerBox}>  
      <Typography style={{width: "99%", paddingTop: "20px"}}
        color="#2c2c2c" textAlign={'center'} fontSize={32} fontFamily={"BN Dime Display"} lineHeight={'36px'} fontWeight={400}>
        THE VIBE STORE
      </Typography>

        <PageSubtitle style={{color:"#2c2c2c", marginTop:"10px", marginBottom: "25px" }}>Unlock rewards by cashing<br/>in your Vibes 🤑</PageSubtitle>
      </div>
        
        {/* TODO: make dynamic later */}
        <RewardComponent
                level={1}
                points={400}
                firstReward={freeDrink}
                secondReward={StickerPack}
            />

        <RewardComponent
                level={2}
                points={1000}
                firstReward={freeDrink}
                secondReward={StickerPack}
            />



    </div>
  );
};

export default OmgRewardsBox;

const styles = StyleSheet.create({
  
  mainBox: {
    width: "90%",
    paddingBottom: "10px",
    backgroundColor: "#FFFFFF",
    left: 0,
    right: 0,
    margin: "auto",
    marginTop: "50px",
    border: "2px solid #2C2C2C",
    borderRadius: 7,
    marginBottom: "50px"
  },
  headerBox: {
    backgroundColor: "#FFFA77",
    borderRadius: 7,
  }
  
  
});