import React, { useEffect, useRef, useState } from "react";
import * as THREE from 'three';
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry';

const RADIUS = 1.55;
const LABEL_SIZE = 2.4



export type ReviewOrderItemProps = {
    tempItemId: string
    labelIamgeSrc?: string
    itemName: string
    width: number
    height: number
}

const colorSpace = THREE.SRGBColorSpace 
const ReviewOrderItem: React.FC<ReviewOrderItemProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {   
    console.log("ReviewOrderItem : ", props.labelIamgeSrc)
    console.log("ReviewOrderItem NAME : ", props.itemName)
     // Set up the scene, camera, and renderer
     const scene = new THREE.Scene();
     scene.background = new THREE.Color(0xDECDFF); 
     const camera = new THREE.PerspectiveCamera(30, props.width / props.height, 0.1, 1000);
     //@ts-ignore
     const renderer = new THREE.WebGLRenderer({antialias: true });
     renderer.setSize(props.width, props.height);
     
     renderer.outputColorSpace = colorSpace;
     
     if (containerRef.current) {
      //@ts-ignore
      containerRef.current.appendChild(renderer.domElement);
    } else {
      console.error("containerRef is null")
    }
    
     // Create the cylinder geometry
     const geometry = new THREE.CylinderGeometry(RADIUS, RADIUS, 10, 64);
     //const material = new THREE.MeshBasicMaterial({ color: 0xaaaaaa });
     const material = new THREE.MeshBasicMaterial({ 
      color: 0xaaaaaa, 
      transparent: true, 
      opacity: 0  // Adjust opacity for transparency
    });
     const cylinder = new THREE.Mesh(geometry, material);
     
    const backgroundPromise = new Promise((resolve, reject) => {
     const backgroundLoader = new THREE.TextureLoader();  

     const canImage = `/images/menu/medium/${props.itemName}.png`
     
     backgroundLoader.load(canImage, function(texture) {
      texture.generateMipmaps = true;
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
      texture.colorSpace = colorSpace;

      const bgGeometry = new THREE.PlaneGeometry(texture.source.data.width / 30, texture.source.data.height / 30);  // Adjust the size as needed
      const bgMaterial = new THREE.MeshBasicMaterial({ map: texture,            transparent: true, 
      opacity: 1.0   });
      const background = new THREE.Mesh(bgGeometry, bgMaterial);
      background.position.set(0, -12, -30);  // Adjust position as needed
      scene.add(background);
      //renderer.render(scene, camera);
      resolve(null);
     });
    })

     scene.add(cylinder);

     // Position the camera
     camera.position.z = 11;
     camera.position.y = 4;
     camera.lookAt(0, 1, 0);

     const labelPromise = new Promise((resolve, reject) => {
     // Load the decal texture
     const loader = new THREE.TextureLoader();

     if ( props.labelIamgeSrc ) {
      loader.load(props.labelIamgeSrc, function(texture) {
        texture.generateMipmaps = true;
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;

        texture.colorSpace = colorSpace;

        
          const decalMaterial = new THREE.MeshBasicMaterial({
              map: texture,
              color: new THREE.Color( 0xffffff ),
              transparent: true,
                depthTest: true,
                depthWrite: false, 
              polygonOffset: true,
              
              polygonOffsetFactor: -4,
          });

          // Create DecalGeometry
          const decalGeometry = new DecalGeometry(cylinder, new THREE.Vector3(0, .6, 1), new THREE.Euler(0, 0, 0), new THREE.Vector3(LABEL_SIZE,LABEL_SIZE,LABEL_SIZE));

          const decal = new THREE.Mesh(decalGeometry, decalMaterial);
          scene.add(decal);

          resolve(null);
      });
      }
    });
    

    Promise.all([backgroundPromise, labelPromise]).then(() => {

        renderer.render(scene, camera);
      })
      
    return () => {
      containerRef.current?.removeChild(renderer.domElement);
      renderer.dispose();
    }
    }, [props.labelIamgeSrc, props.itemName])
  return  <div style={{width: props.width, height: props.height }} ref={containerRef}></div>
}

export default ReviewOrderItem