import { MenuItem } from "../../../../../vale_common"

// This file contains the image overrides for the menu items. Usually the images are configured with in square. These bypass those.
const menuItemImageOverrides = new Map<string, string>()
menuItemImageOverrides.set("cold", "/images/menu/cold small.png")
menuItemImageOverrides.set("pistach", "/images/menu/pistach small.png")
menuItemImageOverrides.set("latte", "/images/menu/latte small.png")
menuItemImageOverrides.set("maple", "/images/menu/maple small.png")
menuItemImageOverrides.set("vanilla", "/images/menu/vanilla small.png")
menuItemImageOverrides.set("matcha", "/images/menu/matcha small.png")
menuItemImageOverrides.set("turmeric", "/images/menu/turmeric small.png")
menuItemImageOverrides.set("p-nut", "/images/menu/p_nut small.png")
menuItemImageOverrides.set("oj", "/images/menu/oj small.png")
menuItemImageOverrides.set("hibiscus", "/images/menu/hibiscus small.png")
menuItemImageOverrides.set("pineapple express-o", "/images/menu/Pineapple Express-o small.png")
menuItemImageOverrides.set("night magik", "/images/menu/night magik small.png")

let menuItemOrder = ["Cold", "Latte", "Vanilla", "Matcha", "Maple", "Turmeric", "P-Nut", "Pistach", "OJ", "Hibiscus", "Pineapple Express-o", "Night Magik"];

export const getOverrideImage = (menuItemName: string | undefined) => {
    if (!menuItemName) return undefined
    return menuItemImageOverrides.get(menuItemName.toLowerCase())
}

export function orderMenuOverride(arrayToSort: MenuItem[]): MenuItem[] {
  const cloneArray = arrayToSort.slice();
  cloneArray.sort((a, b) => {
      let indexA = menuItemOrder.indexOf(a.name!);
      let indexB = menuItemOrder.indexOf(b.name!);
    
      // If both elements are found in orderArray, compare their indices
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
    
      // If only a is found in orderArray, it should come first
      if (indexA !== -1) {
        return -1;
      }
    
      // If only b is found in orderArray, it should come first
      if (indexB !== -1) {
        return 1;
      }
    
      // If neither are found in orderArray, retain their original order
      return 0;
    });
    return cloneArray
}