import { Typography } from '@mui/material';
import React from 'react';
import ValeButtonCustomize from '../../molecules/ValeButtonCustomize';
import PromptPage from '../modal/PromptPage';

interface Reward {
    name: string;
    imageUrl: string;
}

interface RewardComponentProps {
    level: number;
    points: number;
    firstReward: Reward;
    secondReward: Reward;
}

const RewardComponent: React.FC<RewardComponentProps> = ({ level, points, firstReward, secondReward }) => {
    return (
        <div style={styles.container}>
            
            <div style={styles.topBar}>
                <span style={styles.star}>⭐️</span> 
                <span style={styles.star}>⭐️</span> 
                <span style={styles.star}>⭐️</span> 
                <span style={styles.star}>⭐️</span> 
                <span style={styles.star}>⭐️</span> 
                <span style={styles.level}>LEVEL {level}</span> 
                <span style={styles.star}>⭐️</span> 
                <span style={styles.star}>⭐️</span> 
                <span style={styles.star}>⭐️</span> 
                <span style={styles.star}>⭐️</span> 
                <span style={styles.star}>⭐️</span> 
            </div>

            <Typography style={styles.cost}>{points} Vibes</Typography>

            <div style={styles.rewardCardsContainer}>
                <RewardCard reward={firstReward} />
                <RewardCard reward={secondReward} />
            </div>
           
        </div>
    );
};

const RewardCard: React.FC<{ reward: Reward }> = ({ reward }) => (
    <div style={styles.rewardCard}>
        <img src={reward.imageUrl} alt={reward.name} style={styles.rewardImage} />
        <center>

         {/* todo: make button open PromptPage with reward details  */}
        <ValeButtonCustomize
            width={130}
            height={50}
            fontStyle={{fontSize:15, fontWeight:700}}
            style={{color:"black", top: "-20px"}}
          >
            🔒 LOCKED
          </ValeButtonCustomize>
        </center>
    </div>
);


const styles: { [key: string]: React.CSSProperties } = {
    container: {
        backgroundColor: "white",

    },
    topBar: {
        backgroundColor: "#FF00B8",
        height: "38px",
        lineHeight: "38px",
        color: "white",
        fontFamily: "Inter",
        fontWeight: 600,
        fontSize: "18px",
        textAlign: "center"
    },
    star: {
        padding: "0 1%",
    },
    level: {
        padding: "0 2%",
    },
    cost: {
        color: "#F64AC3",
        textAlign: "center",
        fontFamily: "Inter",
        fontWeight: 600,
        marginTop: "10px"

    },
    rewardCardsContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "0px",
    },
    rewardCard: {
        width: "48%",
        backgroundColor: "#ffffff",
        padding: "16px",
        textAlign: "center",
    },
    rewardImage: {
        width: "100%",
        height: "auto",
        borderRadius: "4px",
        marginBottom: "8px",
    },
    
    
      
};

export default RewardComponent;
