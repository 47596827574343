import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

export const PageTitle:React.FC<{style?: React.CSSProperties} & PropsWithChildren> = ({children, style}) => {
    return <Box marginTop={4} style={{display: 'flex', justifyContent: 'center'}}>
        <Typography color="#2c2c2c" maxWidth={292} textAlign={'center'} fontSize={42} fontFamily={"BN Dime Display"} lineHeight={'36px'} fontWeight={400} style={{wordWrap: 'break-word', ...style}}>{children}</Typography>
    </Box>
}

export const PageSubtitle:React.FC<{style?: React.CSSProperties} & PropsWithChildren> = ({children, style}) => {
    return  <Box style={{display: 'flex', justifyContent: 'center'}}><Typography maxWidth={263} lineHeight={1.1} textAlign={"center"} color={"white"} fontSize={18} fontFamily={"Inter"} fontWeight={600} style={{ textAlign: "center", wordWrap: 'break-word', ...style }}>{children}</Typography></Box>

}
