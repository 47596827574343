import React from "react";
import Page from "../../../templates/Page";
import Footer from '../../../molecules/footer';

const AccountToggle: React.FC = () => {
  const styles: { [key: string]: React.CSSProperties } = {
    
    toggleContainer: {
      height: "54px",
      backgroundColor: "#FF00B8",
      width: "80%",
      border: "3px solid #2C2C2C",
      borderRadius: "27px",
      position: "absolute",
      top: "185px",
      left: "0",
      right: "0",
      margin: "auto",
      overflow: "hidden",
    },
    selected: {
      backgroundColor: "#FFFA77",
      height: "54px",
      width: "50%",
      borderTopRightRadius: "27px",
      borderBottomRightRadius: "27px"
      
    }


  };

  return (

      <div style={styles.toggleContainer}>

        <div style={styles.selected}>

        </div>

      </div>


  );
};

export default AccountToggle;
