import React from "react";
import { Box, Typography } from "@mui/material";
import { StyleSheet } from "react-native";
import { Title } from "react-native-paper";
import { PageSubtitle, PageTitle } from "../../atoms/TextStyles";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";

const LearnHowBox = () => {
  
  return (
    <div style={styles.mainBox}>
        
        <img src="./images/rewards-drink.png" style={{float:"left", height:"100%"}} />
        

        <span style={{display: "inline-block", width: "50%", marginTop: "40px", marginLeft: "20px", fontFamily: "BN Dime Display", fontSize: 26, fontWeight:400, color: "#2C2C2C"}}>EARN VIBES,<br/>GET FREE<br/>STUFF</span>

        <div style={{display: "inline-block", width: "50%", marginTop: "20px", marginLeft: "20px"}}>
        <ValeLaunchButton
            width={135}
            height={46}
            fontStyle={{fontSize:16}}
            withArrow={true}
          >
            LEARN HOW
          </ValeLaunchButton>
          </div>

    </div>
  );
};

export default LearnHowBox;

const styles = StyleSheet.create({
  
  mainBox: {
    backgroundColor: "#FFFA77",
    height: "211px",
    border: "2px solid #2C2C2C",
  },
  
  
});