import React, { useEffect, useRef, useState } from 'react';
import TruncatedText from './TruncateText';
import ShowMoreText from "react-show-more-text";
import { Typography } from '@mui/material';
//import { useCollapse } from "react-collapsed";
interface ShrinkMenuImageProps {
  description: string;
  iconUrl: string | null;
  name: string;
  checkAllergy: any;
}

const minHeightDesc = 30
const descTextStyle = {color: '#4712B1', fontSize: 12, fontWeight: 500, fontFamily: 'Inter'}
const expandTextStyle = {color: '#E30A8F', fontSize: 12, fontWeight: 700, fontFamily: 'Inter'}

const MoreText = () => <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.30474 4.66638L4.1053 8.1742L0.848044 4.7242L0 5.51443L4.12458 9.87028L8.17205 5.4566L7.30474 4.66638Z" fill="#E30A8F"/>
  <path d="M7.30474 0L4.1053 3.50782L0.848044 0.057821L0 0.848044L4.12458 5.2039L8.17205 0.790222L7.30474 0Z" fill="#E30A8F"/>
</svg>

const LessText = () => <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.867137 5.20386L4.06657 1.69604L7.32383 5.14604L8.17188 4.35581L4.0473 -4.48227e-05L-0.000179291 4.41364L0.867137 5.20386Z" fill="#E30A8F"/>
    <path d="M0.867137 9.87024L4.06657 6.36242L7.32383 9.81242L8.17188 9.0222L4.0473 4.66634L-0.000179291 9.08002L0.867137 9.87024Z" fill="#E30A8F"/>
  </svg>


const ShrinkMenuImage: React.FC<ShrinkMenuImageProps> = ({ description, iconUrl, name, checkAllergy }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({collapsedHeight: minHeightDesc});
  // const divRef = useRef(null);
  // const [canExapnd, setCanExpand] = useState(false);
  
  // useEffect(() => {
  //   if (divRef.current) {
  //     //@ts-ignore
  //     const height = divRef.current.offsetHeight 
  //     setCanExpand(height > minHeightDesc)
  //   }
  // }, []); // Empty dependency array to run the effect only after the first render

  return (
    <div style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: "4px",
        paddingRight: "4px",
        
      }}>
        
        <div style={{ width: "100%"}}>
        <Typography style={{
          color: '#FF00B8',
          fontSize: 24,
          fontFamily: 'BN Dime Display',
          fontWeight: '400',
          wordWrap: 'break-word',
          textAlign: "center",
          marginTop:8,
          lineHeight: '24px',
        }}>{name?.toUpperCase()}</Typography>
      </div>
      <div
        style={{
          color: '#4712B1',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '500',
          wordWrap: 'break-word',
          textAlign: "center",
          position: 'relative',
          width: "100%",
        }}>
        <ShowMoreText more={<MoreText/>} less={<LessText/>} onClick={(expanded) => setIsExpanded(expanded)} lines={2}>{description}</ShowMoreText>

      </div>
      <div style={{height: "100%",  width: "100%", overflow: "hidden", position: 'relative', display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
          
          <div  style={{
                position: "absolute",
                marginTop: isExpanded ? "-10px" : "-30px",
                top : 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex", flexDirection: "column", justifyContent: "flex-end"
              }}>

            <img
              style={{
                maxHeight: '160px',
                width: "100%",
                height: "100%",
                objectFit: "contain",
                
              }}
              src={iconUrl!}
            />
            
      </div>
            <div style={{
                position: "absolute",
                top : 0,
                right: 10,
              }}>
              {isExpanded ? null: checkAllergy(name)}
              </div>
            </div>


    </div>
  );
};

export default ShrinkMenuImage;
