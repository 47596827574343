import React from "react";
import Page from '../../templates/Page';
import { Typography } from "@mui/material";
import { PageTitle } from "../../atoms/TextStyles";
import { StyleSheet } from "react-native";
import AccountToggle from "../home/account/AccountToggle";
import RewardBox from "./RewardBox";
import Footer from "../../molecules/footer";
import NextRewardComp from "./NextRewardComp";
import LearnHowBox from "./LearnHowBox";
import OmgRewardsBox from "./OmgRewardsBox";

const LoyaltyRewardHome = () => {
  
  return (
    <Page>

      <PageTitle style={{marginTop: "30px"}}>MY REWARDS</PageTitle>

      <img
          src="./images/youraccount-hero 1.jpg"
          alt="Rewards Hero"
          style={styles.heroImage}
        />

        <AccountToggle />

        <RewardBox />

        <NextRewardComp />

        <LearnHowBox />

        <OmgRewardsBox />

        <Footer />

    </Page>
  );
};

export default LoyaltyRewardHome;

const styles = StyleSheet.create({
  
  heroImage: {
    width: '100%',
    height: 'auto',
    marginTop: '50px',

  },



});