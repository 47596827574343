import React from "react";
import { Box, Typography } from "@mui/material";
import { StyleSheet } from "react-native";

const NextRewardComp = () => {
  return (
    <div style={styles.mainBox}>
      <div style={styles.content}>
        <img src="./images/rewards-sun.png" alt="Reward Sun" style={styles.rewardImage} />
        <Typography style={styles.text}>
            More vibes until <br /> your next reward 💪
        </Typography>
      </div>
    </div>
  );
};

export default NextRewardComp;

const styles = StyleSheet.create({
  mainBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: 30,
    paddingBottom: 40,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  rewardImage: {
    width: 65,
    marginRight: 15,
  },
  text: {
    fontSize: 18,
    lineHeight: 1,
    color: 'white',
  },
  boldText: {
    fontWeight: 'bold',
  },
});
