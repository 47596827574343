import { StackNavigationProp } from "@react-navigation/stack";
import { OrderStep, getNextMenuItemStep } from "./menu_utils";
import { MenuStackParamList } from "./menu_param_list";
import { OrderStateList, MenuItemsMap, OrderState } from "../../../vale_common";

export const SeralizeOrderSelectionState = (state: OrderStateList): string => {
  return btoa(JSON.stringify(state));
};

export const deSeralizeOrderSelectionState = (
  param: string | undefined,
): OrderStateList | undefined => {
  if (!param) return undefined;
  return JSON.parse(atob(param)) as OrderStateList;
};

export const pushNextMenuItemStep = (
  navigation: StackNavigationProp<MenuStackParamList>,
  menu: MenuItemsMap,
  state?: OrderStateList,
  currentStepId?: OrderStep,
  orderName?: string,
) => {
  if (!state || state.length === 0) {
    navigation.push("selection", { orderName });
    return;
  }

  navigation.push("summary", {
    order: SeralizeOrderSelectionState(state!),
    orderName,
  });
};

export const pushNextMenuItemStepWithRemovingOrder = (
  removalIndex: number,
  navigation: StackNavigationProp<MenuStackParamList>,
  menu: MenuItemsMap,
  previousState?: OrderStateList,
  currentStepId?: OrderStep,
  orderName?: string,
) => {
  const newState = [...previousState!];
  newState?.splice(removalIndex, 1);
  return pushNextMenuItemStep(
    navigation,
    menu,
    newState,
    currentStepId,
    orderName,
  );
};

export const pushNextMenuItemStepWithNewOrder = (
  newItem: OrderState,
  navigation: StackNavigationProp<MenuStackParamList>,
  menu: MenuItemsMap,
  orderUiState?: OrderStep,
  previousState?: OrderStateList,
  currentStepId?: OrderStep,
  orderName?: string,
) => {
  const updatedState = previousState ? [...previousState, newItem] : [newItem];
  return pushNextMenuItemStep(
    navigation,
    menu,
    updatedState,
    currentStepId,
    orderName,
  );
};
